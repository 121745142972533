import React from "react"
import mq from '@styles/mq'
import { graphql, Link } from 'gatsby'
import { Layout } from "@components/Layout";
import { SEO, mapToSEO } from "@components/SEO";
import { Banner, mapToPageHeader } from "@components/Banner";
import { mapToKiwiVIPVisible, SignupBannerWrapper } from "@components/SignupBanner";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { mapToRedesignBanner } from "@components/RedesignPromoBanner/mapper";
import theme from "@styles/theme";
import PlateDesignPromotionBanner from "@components/PlateDesignPromoBanner/PlateDesignPromoBanner";
import { QuickCombinationFinder } from "@components/CombinationFinder";
import { H2, H4, Paragraph1 } from "@styles/Global.styles";
import { Grid, Responsive } from "semantic-ui-react";
import { mapToImageSlider } from "@mappers/ImageSlider/mapper";
import { ImageSlider } from "@components/ImageSlider";
import { mapToInfoBannerContainer, ImageInformationList } from "@components/ImageInformationList";
import { ImageSliderModel } from "@models/ImageSlider/ImageSlider";
import { FeaturedPlateFormatBanner } from "@components/FeaturedPlateFormatBanner";
import { mapToPlateFormatList } from "@mappers/PlateFormat/mapper";
import { InfoImageSlider, mapToInfoImageSlider } from "@components/InfoImageSlider";
import styled from "@emotion/styled";
import { RedesignPromoBannerDescription } from "@components/PlateDesignPromoBanner/PlateDesignPromoBanner.styles";

type Props = any

class PlateTextColour extends React.Component<Props>{

  render() {
    const { data, location } = this.props;
    const elements = data.kontentItemPlateTextColourPromoPage.elements;

    const plateDesignBannerData = mapToRedesignBanner(elements.plate_design_banner.linked_items[0].elements);
    const imageSliderData = mapToImageSlider(elements.featured_plate_designs.linked_items);
    const infoImageSliderData = mapToInfoImageSlider(elements.button_banner.linked_items);
    const featuredPlateFormatData = mapToPlateFormatList(elements.featured_plate_formats.linked_items[0].elements.plate_fromats)
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const seo = mapToSEO(elements);
    const metadata = mapToPageHeader(elements);
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const reCaptchaSiteKey = this.props.data.site.siteMetadata.reCaptchaSiteKey;
    const reCaptchaSiteKeyV2 = this.props.data.site.siteMetadata.reCaptchaSiteKeyV2;

    const PlateTextColourBanner = styled.div`
    > div:first-of-type {
      ${mq({
      height: ['220px', '280px', '360px']
    })}
    }
    > .container {
      ${mq({
      marginTop: ['-107px', '-140px', '-180px'],
      marginBottom: ['56px', '96px', '118px']
    })}
    }
    `

    return (
      <Layout location={location}>
        <SEO {...seo} />
        <PlateTextColourBanner>
          <Banner textVerticalAlign='middle' {...metadata} isBiggerBanner={false} />
        </PlateTextColourBanner>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 60,
              bottom: 60
            },
            desktop: {
              top: 80,
              bottom: 80
            }
          }}
          backgroundColor={theme.brand.colors.lightGrey}>
          <PlateDesignPromotionBanner banner={plateDesignBannerData}></PlateDesignPromotionBanner>
        </GenericBannerContainer>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 60,
              bottom: 0
            },
            desktop: {
              top: 80,
              bottom: 10
            }
          }}
          backgroundColor={theme.brand.colors.beige}>
          <QuickCombinationFinder hideVehicleOptions={true} title="Choose a Combination" subtitle='Find a combination, choose a design and add a font colour.' reCaptchaSiteKey={reCaptchaSiteKey}
                reCaptchaSiteKeyV2={reCaptchaSiteKeyV2} apiUrl={apiUrl}/>
        </GenericBannerContainer>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 0,
              bottom: 60
            },
            desktop: {
              top: 20,
              bottom: 80
            }
          }}
          backgroundColor={theme.brand.colors.beige}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign='center'>
                <H4>Already own a plate? <Responsive as='br' maxWidth={theme.responsive.mobile.maxWidth}></Responsive> Restyle it <Link to='/restyle'>here</Link></H4>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </GenericBannerContainer>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 0,
              bottom: 0
            },
            desktop: {
              top: 0,
              bottom: 0
            }
          }}
          backgroundColor={theme.brand.colors.lightGrey}>
          {
            imageSliderData.map((imageSlider: ImageSliderModel, i: number) => {
              return <GenericBannerContainer
                padding={{
                  mobile: {
                    top: i > 0 ? 10 : 80,
                    bottom: 80
                  },
                  desktop: {
                    top: i > 0 ? 30 : 100,
                    bottom: 100
                  }
                }}
                backgroundColor={theme.brand.colors.lightGrey}>
                <ImageSlider showDots={false} slidesToShow={4} images={imageSlider} />
              </GenericBannerContainer>
            })
          }

        </GenericBannerContainer>
        <InfoImageSlider  {...infoImageSliderData} />
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 60,
              bottom: 60
            },
            desktop: {
              top: 80,
              bottom: 80
            }
          }}
          backgroundColor={theme.brand.colors.beige}>
          <FeaturedPlateFormatBanner title={elements.featured_plate_formats.linked_items[0].elements.heading.value} plateFormats={featuredPlateFormatData}></FeaturedPlateFormatBanner>
        </GenericBannerContainer>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 60,
              bottom: 60
            },
            desktop: {
              top: 80,
              bottom: 80
            }
          }}
          backgroundColor={theme.brand.colors.white}>
          <Grid>
            <Grid.Column textAlign='center' width={16}>
              <H2>
                {elements.text_container.linked_items[0].elements.heading.value}
              </H2>
              <RedesignPromoBannerDescription dangerouslySetInnerHTML={{ __html: elements.text_container.linked_items[0].elements.body.value }}></RedesignPromoBannerDescription>
            </Grid.Column>
          </Grid>
        </GenericBannerContainer>
        {
          kiwiVip.visible &&
          <SignupBannerWrapper version={kiwiVip.version} />
        }
      </Layout>
    )
  }
}

export const query = graphql`
query($slug: String!){
    site {
      siteMetadata {
        apiUrl
        reCaptchaSiteKey
        reCaptchaSiteKeyV2
      }
    }
    kontentItemPlateTextColourPromoPage(fields: { slug: { eq: $slug }}) {
      elements {
        text_container {
            linked_items {
              ... on KontentItemOrderConfirmationMessage {
                elements {
                  body {
                    value
                  }
                  heading {
                    value
                  }
                }
              }
            }
          }
        button_banner {
            linked_items {
              ... on KontentItemInfoImageSliderItem {
                elements {
                  image {
                    value {
                      url
                      name
                      description
                    }
                  }
                  description {
                    value
                  }
                  name {
                    value
                  }
                  button__text {
                    value
                  }
                  button__button_type {
                    value {
                      codename
                    }
                  }
                  button__button_color_theme {
                    value {
                      codename
                    }
                  }
                  button__url_slug {
                    value
                  }
                }
              }
            }
          }
        featured_plate_formats {
          linked_items {
            ... on KontentItemPlateFormatBanner {
              elements {
                heading {
                  value
                }
                plate_fromats {
                  linked_items {
                    ... on KontentItemProductCategoryFormats {
                      elements {
                        base_price {
                          value
                        }
                        format_type {
                          value {
                            codename
                            name
                          }
                        }
                        description {
                          value
                        }
                        example_image {
                          value {
                            url
                          }
                        }
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        featured_plate_designs {
          linked_items {
            ... on KontentItemImageSlider {
              elements {
                images {
                  value {
                    width
                    url
                    type
                    size
                    name
                    height
                    description
                  }
                }
                show_button {
                  value {
                    codename
                    name
                  }
                }
                subheading {
                  value
                }
                heading {
                  value
                }
                button__url_slug {
                  value
                }
                button__text {
                  value
                }
                button__button_type {
                  value {
                    codename
                    name
                  }
                }
                button__button_color_theme {
                  value {
                    codename
                    name
                  }
                }
              }
            }
          }
        }
        general_page_content__header_image {
          value {
            description
            height
            name
            size
            type
            url
            width
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        plate_design_banner {
          linked_items {
            ... on KontentItemPlateDesignPromoBanner {
              id
              elements {
                background_colors {
                  value {
                    codename
                    name
                  }
                }
                heading {
                  value
                }
                image {
                  value {
                    width
                    url
                    type
                    size
                    name
                    height
                    description
                  }
                }
                image_1 {
                  value {
                    description
                    height
                    name
                    size
                    type
                    url
                    width
                  }
                }
                summary {
                  value
                }
                text_colors {
                  value {
                    codename
                    name
                  }
                }
              }
            }
          }
        }
        general_page_content__title_position {
          value {
            codename
            name
          }
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url {
          value
        }
      }
    }
  }
`

export default PlateTextColour