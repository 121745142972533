import styled from "@emotion/styled";
import theme from "@styles/theme";
import { H5 } from "@styles/Global.styles";
import mq from '@styles/mq'
import { Button, Grid } from "semantic-ui-react";

export const GridNoMargin = styled(Grid)`
    margin-top: 0 !important;
`

export const FeaturedPlateFormatContainer = styled.div`
    background-color: ${theme.brand.colors.white};
    box-shadow: 0px 6px 16px #0000001F;
    border-radius: 12px;
    padding: 40px;
    position: relative;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 95%;

    ${mq({
        marginBottom: ['30px', '0', '0'],
        padding: ['20px', '30px', '30px'], 
    })}

    > .row {
        display: flex;
        flex: 1;
    }
`

export const FeaturedPlateFormatPlateImage = styled.img`
    width: 100%;
    margin-top: 15px;
`

export const FeaturedPlateFormatTitle = styled(H5)`
    display: inline-block;
`

export const FeaturedPlateFormatPrice = styled.div`
    > span {
        color: ${props => props.color};
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        margin-left: 3px;
    }    

    > label {
        color: #B7B7B7;
        font-weight: 100;
        font-size: 13px;
        letter-spacing: -0.26px;
        vertical-align: super;
    }

    display: inline-block;
`

export const FeaturedPlatesSliderButton = styled(Button)`
    background-color: transparent !important;
    padding: 0 !important;

    > i {
        margin: 0 !important;
        width: auto !important;
    }

    &.slick-next {
        right: -30px !important;
    }

    &:before {
        display: none;
    }
`