import { KenticoRedesignPromoBanner, RedesignPromoBanner } from "./Types"
import { convertKontentColorToHex } from "@utils/Helpers"

export const mapToRedesignBanner = (data: KenticoRedesignPromoBanner): RedesignPromoBanner => {
    return {
        title: data.heading.value,
        description: data.summary.value,
        image1: {
            description: data.image.value[0].description,
            url: data.image.value[0].url
        },
        image2: {
            url: data.image_1.value[0].url,
            description: data.image_1.value[0].description
        },
        button : data.button__text !== undefined ? {
            text: data.button__text.value,
            theme: convertKontentColorToHex(data.button__button_color_theme.value[0].codename),
            type: data.button__button_type.value[0].codename,
            url: data.button__url_slug.value
        } : undefined
    }
}