import { mapToButton } from "@utils/mapper";
import { ImageSliderModel } from "@models/ImageSlider/ImageSlider";

export const mapToImageSlider = (data: any): ImageSliderModel[] => {
    return data.map((element: any) => {
        const kontentItem = element.elements;
        let kontentItemMapped = {
            button: mapToButton(kontentItem),
            heading: kontentItem.heading.value,
            images: [],
            showButton: kontentItem.show_button.value.length > 0 && kontentItem.show_button.value[0].codename === 'yes',
            subHeading: kontentItem.subheading.value
        };
        let kontentItemImagesMapped = kontentItem.images.value.map(function(image: any) {
                return {
                    url: image.url,
                    description: image.description
                }
            });

        kontentItemMapped.images = kontentItemImagesMapped;
        return kontentItemMapped;
    });
}