import React from 'react'
import { Grid } from 'semantic-ui-react'
import { LazyLoadImage} from "@elements/index"
import { H2, H4 } from '@styles/Global.styles'
import { RedesignPromoBanner } from '@components/RedesignPromoBanner/Types'
import { RedesignPromoBannerDescription, RedesignPromoBannerHeading } from './PlateDesignPromoBanner.styles'

interface BannerProps {
    banner: RedesignPromoBanner
}


const PlateDesignPromotionBanner = ({banner}:BannerProps)=>{
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16} textAlign='center'>
                    <RedesignPromoBannerHeading>
                        {banner.title}
                    </RedesignPromoBannerHeading>
                    <RedesignPromoBannerDescription>
                        {banner.description}
                    </RedesignPromoBannerDescription>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column mobile={16} tablet={7} computer={5}>
                    <LazyLoadImage src={banner.image1.url} desc={banner.image1.description} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={7} computer={5}>
                    <LazyLoadImage src={banner.image2.url} desc={banner.image2.description} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
export default PlateDesignPromotionBanner