import React from 'react'
import styled from '@emotion/styled'
import { Segment,Container, Icon, GridColumn } from 'semantic-ui-react'
import mq from '@styles/mq'

import theme from '@styles/theme'
import { H1, H2, H3, H4 } from '@styles/Global.styles'

export const BannerSegment = styled(({backgroundColor, ...rest})=><Segment {...rest} />)`
    &&&&{
        margin: 0;
        padding: 0;
        background-color: ${props=>props.backgroundColor}
    }
`
export const RedesignPromoBannerHeading = styled(H1)`
    margin-bottom: 5px;
`
export const RedesignPromoBannerDescription = styled(H4)`
    font-weight: normal !important;
    margin-top: 0;
`
export const BannerContainer = styled(Container)`
    color: ${theme.brand.colors.white};
    padding: 40px 0;
`
export const ImageSeperatorIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   
`

export const ImageSeperatorIconContainer = styled(GridColumn)`
    ${
        mq({
            margin: ['25px 0 !important','0','0']
        })
    }
`
