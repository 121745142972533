import React from 'react'
import { PlateFormat } from '@models/PlateFormat/PlateFormat';
import { Grid, Segment, Container } from 'semantic-ui-react';
import { 
    FeaturedPlateFormatContainer, 
    FeaturedPlateFormatPlateImage, 
    FeaturedPlateFormatTitle, 
    FeaturedPlateFormatPrice, 
    GridNoMargin
} from './FeaturedPlateFormatBanner.styles';
import { Paragraph2, H3, H2 } from '@styles/Global.styles';
import theme from '@styles/theme';
import { KpButton } from '@elements/index';

interface FeaturedPlateFormatBannerProps {
    title: string,
    plateFormats: PlateFormat[]
}

/* eslint-disable jsx-a11y/label-has-associated-control */

const FeaturedPlateFormatBanner = (props: FeaturedPlateFormatBannerProps) => {
    const {title, plateFormats} = props;

    return (
        <Segment basic>
            <Container>
            <Grid>
                <Grid.Row>
                    <Grid.Column textAlign='center' width={16}>
                        <H2>
                            {title}
                        </H2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered stretched>
                    {
                        plateFormats.map((plateFormat, index) => {
                            return (
                                <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={7} widescreen={6} key={index}>
                                    <FeaturedPlateFormatContainer>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column mobile={16} tablet={16} computer={7}>
                                                            <FeaturedPlateFormatTitle>
                                                                {plateFormat.name}
                                                            </FeaturedPlateFormatTitle>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={16} computer={9}>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column width={6} floated='left' textAlign='left'>
                                                                        <FeaturedPlateFormatPrice color={theme.brand.colors.green}>
                                                                            <label>
                                                                                From
                                                                            </label>
                                                                            <span>
                                                                                ${plateFormat.basePrice}
                                                                            </span>
                                                                        </FeaturedPlateFormatPrice>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={10} floated='right' textAlign='right'>
                                                                        <FeaturedPlateFormatPrice color={theme.brand.colors.orange}>
                                                                            <label>
                                                                                Layby from
                                                                            </label>
                                                                            <span>
                                                                                ${Math.ceil(plateFormat.basePrice/12)}
                                                                            </span>
                                                                        </FeaturedPlateFormatPrice>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                                            <Paragraph2>
                                                                {plateFormat.description}
                                                            </Paragraph2>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                                <GridNoMargin centered>
                                                    <Grid.Column mobile={12} tablet={10} computer={10}>
                                                        <FeaturedPlateFormatPlateImage src={plateFormat.plateImageUrl}/>
                                                    </Grid.Column>
                                                </GridNoMargin>

                                    </FeaturedPlateFormatContainer>
                                </Grid.Column>
                            )
                        })
                    }
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign='center' width={16}>
                        <KpButton id="fpfb-cy" color={theme.brand.colors.blue} buttonType='primary' link='/create'>
                            CREATE YOURS
                        </KpButton>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Container>
        </Segment>
        
    );
}

export default FeaturedPlateFormatBanner